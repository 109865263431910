import React from "react";

function SkillsSection() {
  return (
    <section className="skills-section">
      <div className="skills-container">
        <div>
          <SkillBar skillName="HTML" percentage={90} />
          <SkillBar skillName="CSS" percentage={90} />
          <SkillBar skillName="JavaScript" percentage={80} />
          <SkillBar skillName="TypeScript" percentage={50} />
          <SkillBar skillName="React" percentage={85} />
          <SkillBar skillName="Angular" percentage={40} />
        </div>
        <div>
          <SkillBar skillName="Node.js" percentage={60} />
          <SkillBar skillName="Express" percentage={60} />
          <SkillBar skillName="MySQL" percentage={65} />
          <SkillBar skillName="UI Design" percentage={70} />
          <SkillBar skillName="Figma/XD" percentage={85} />
        </div>
      </div>
    </section>
  );
}

function SkillBar({ skillName, percentage }) {
  const barStyle = {
    width: `${percentage}%`,
  };

  return (
    <div className="skill-bar">
      <div className="skill-name raleway">{skillName}</div>
      <div className="bar-container">
        <div className="bar" style={barStyle}></div>
      </div>
    </div>
  );
}

export default SkillsSection;
