import React from 'react';
import projectsData from '../data/ProjectsData';
import CallMadeIcon from '@mui/icons-material/CallMade';

function ProjectSection() {
  return (
    <section className="project-section">
      <div className="project-grid">
        {projectsData.map((project, index) => (
          <div className="project" key={index}>
            <a href={project.website} target="_blank" rel="noopener noreferrer">
              <h3 className='subtitle lightgray-font raleway lightbold subtitle-margin'>{project.title}<CallMadeIcon/></h3>
            </a>
            <p className='general-content gray-font raleway line-height-content'>{project.description}</p>
            <div className="skills">
              {project.skills.map((skill, index) => (
                <div className="skill raleway lightbold" key={index}>
                  {skill}
                </div>
              ))}
            </div>
            <img src={project.image} alt={project.title} className='img-projects' />
          </div>
        ))}
      </div>
    </section>
  );
}

export default ProjectSection;
