import './App.css';
import {Route, Navigate, Routes} from "react-router-dom";
import HomePage from './HomePage';




function App() {
  return (
    <main>
      <Routes>
        <Route path="/home" element={<HomePage/>} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </main>
  );
}


export default App;
