const RecommendationsData = [
  {
    description:
      "I was so lucky to be placed on the same table as Erwan during our first week of school. We instantly clicked and ended up working on several projects together. I found him to be incredibly hardworking, responsible and organised and always up for a laugh! He would definitely be an asset to any new organisation or workplace.",
    title: "- Marissa, Classmate",
  },
  {
    description:
      "Erwan is a team player who adds humor and friendliness to any project process. He is smart, dedicated and hard-working and determined to find a good solution. Erwan will prove a welcome addition to any workplace, because he will add a good working atmosphere, hard work and imaginative suggestions to anything he does.",
    title: "- Line, Senior Lecturer",
  },
  {
    description:
      "Erwan deserves a few words to go on with. He was nice, polite, very respectful and curious. He adapted quickly to my style. Even through technical difficulties, he showed a lot of responsibilities and came to help right away. He worked very well within his classmates' group, giving each other space, very respectful to each others and a great expertise in different fields. I wish you luck on your life's journey, I wish you all the best, you deserve it!",
    title: "- Helle, Project Client",
  },
  {
    description:
      "Erwan is a sweet, loveable, and appreciated coworker who fulfills any task given. From day one he was a part of our company and participated in the daily workflow as anyone else.. He was always listening to our wishes and demands and did a great job creating both. Erwan is very talented and has a natural calmness which makes him reliable. He knows what he is doing and is honest about the things he can or can’t do. He has a skill in development and a fantastic work ethic. He does not mind staying 10 minutes late to finish a task, and he is always polite and smiling. ",
    title: "- Mia, Internship and Thesis supervisor",
  },
];

export default RecommendationsData;
