import RecommendationsData from "../data/RecommendationData";

function ProjectSection() {
  return (
    <section className="referencer-section">
      <div className="referencer-grid">
      {RecommendationsData.map((referencer, index) => (
        <div className="referencer" key={index}>
          <p className="general-content line-height-content gray-font raleway">
            {referencer.description}
          </p>
          <h3 className="name-referencer red-font raleway lightbold referencer-margin">
            {referencer.title}
          </h3>
        </div>
      ))}
      </div>
    </section>
  );
}

export default ProjectSection;
