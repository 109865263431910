import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Logo from "../img/logo.png";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <main className="footer-cont">
      <div className="arrow-footer-cont">
        <KeyboardDoubleArrowUpIcon
          onClick={scrollToTop}
          className="arrow-footer"
        />
      </div>
      <section>
        <div className="icons-cont-footer">
          <a
            href="https://www.instagram.com/mocqueterwan/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="icon" />
          </a>
          <a
            href="https://github.com/ErwanMocquet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon className="icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/erwan-mocquet-02baa1192/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon className="icon" />
          </a>
        </div>
        <div className="logo-footer-cont">
          <img
            className="logo-footer"
            src={Logo}
            alt="Erwan Mocquet's portfolio"
          />
        </div>
        <div className="last-text-footer-cont">
            <p className="lightgray-font raleway lightbold line-height-content last-footer">Designed & Developed by <br/>Erwan Mocquet © 2023</p>
        </div>
      </section>
    </main>
  );
}

export default Footer;
