import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EastIcon from "@mui/icons-material/East";
import mypicture from "./img/mypicture.png";
import Skills from "./components/Skills";
import ProjectSection from "./components/ProjectSection";
import RecommendationsSection from "./components/RecommendationSection";
import Footer from "./components/Footer";

export default function HomePage() {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      let currentActiveSection = "";

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0) {
          currentActiveSection = section.id;
        }
      });

      setActiveSection(currentActiveSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="blue-bg">
      {/* LANDING SECTION */}
      <section className="landing-section">
        <h1
          className="title lightgray-font raleway line-height"
          id="title-border"
        >
          Hello, I'm <i className="red-font">Erwan Mocquet</i>.
          <br /> I'm a full-stack web developer.
        </h1>
        <div className="icons-cont">
          <a
            href="https://www.instagram.com/mocqueterwan/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className="icon" />
          </a>
          <a
            href="https://github.com/ErwanMocquet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon className="icon" />
          </a>
          <a
            href="https://www.linkedin.com/in/erwan-mocquet-02baa1192/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon className="icon" />
          </a>
        </div>
        <div className="btn-cont">
          <Link to="about" smooth={true} duration={500}>
            <button className="btn raleway title">
              View my work
              <EastIcon className="btn-icon" />
            </button>
          </Link>
        </div>
      </section>
      <section className="general-layout">
        {/* ABOUT SECTION */}
        <section id="about">
          <div
            className={`sticky-title ${
              activeSection === "about" ? "active" : ""
            }`}
          >
            <h1 className="title lightgray-font bold raleway title-margin">
              ABOUT
            </h1>
          </div>
          <div className="img-about-cont">
            <img
              src={mypicture}
              alt="Erwan Mocquet"
              className="img-about"
            ></img>
          </div>
          <div className="about-text-cont">
            <p className="gray-font raleway general-content line-height-content margin-bottom-content">
              Hello! I'm Erwan Mocquet, a
              <i className="focus"> Frontend/Fullstack developer </i>
              specializing in web development. With a recent
              <i className="focus"> AP Degree in Multimedia Design</i>, I excel
              at creating responsive, dynamic, and contemporary websites. My
              expertise lies in frontend technologies, and I also have
              experience in backend development.
            </p>
            <p className="gray-font raleway general-content line-height-content margin-bottom-content">
              Passionate about web development, I excel in frontend and backend
              technologies. I'm eager to work with companies focused on
              <i className="focus">
                {" "}
                sustainability, international outreach, language support, new
                technologies,
              </i>{" "}
              or <i className="focus"> game development</i>. I thrive in diverse
              industries and
              <i className="focus"> enjoy tackling unique challenges</i>.
            </p>
            <p className="gray-font raleway general-content line-height-content margin-bottom-content">
              As a fresh graduate, I'm committed to delivering exceptional
              results through my problem-solving mindset, well-structured coding
              approach, and adaptability to new challenges. I thrive in
              collaborative environments, emphasizing effective{" "}
              <i className="focus"> communication </i>
              and contributing to <i className="focus"> collective success</i>.
            </p>
          </div>
        </section>
        {/* SKILLS SECTION */}
        <section id="skills">
          <div
            className={`sticky-title ${
              activeSection === "skills" ? "active" : ""
            }`}
          >
            <h1 className="title lightgray-font bold raleway title-margin">
              SKILLS
            </h1>
          </div>
          <Skills />
        </section>
        <section id="projects">
          <div
            className={`sticky-title ${
              activeSection === "projects" ? "active" : ""
            }`}
          >
            <h1 className="title lightgray-font bold raleway title-margin">
              PROJECTS
            </h1>
          </div>
          <ProjectSection />
        </section>
        <section id="recommendations">
          <div
            className={`sticky-title ${
              activeSection === "recommendations" ? "active" : ""
            }`}
          >
            <h1 className="title lightgray-font bold raleway title-margin">
              RECOMMENDATIONS
            </h1>
          </div>
          <RecommendationsSection />
        </section>
        <section id="contact">
          <div
            className={`sticky-title ${
              activeSection === "contact" ? "active" : ""
            }`}
          >
            <h1 className="title lightgray-font bold raleway title-margin">
              CONTACT
            </h1>
          </div>
          <div className="content-contact-cont">
            <p className="geenral-content gray-font raleway line-height-content">
              If you're seeking a dedicated Frontend/Full-stack developer with a
              passion for crafting remarkable digital experiences, I would be
              thrilled to <i className="focus"> connect with you</i>. Let's
              embark on a <i className="focus"> journey </i> of innovation{" "}
              <i className="focus"> together</i>!
            </p>
          </div>
          <div className="contact-cont">
            <h2 className="general-content white-font raleway">
              <span className="bold">Tel: </span>
              <a href="tel:+4550690166"> +45 5069 0166</a>
            </h2>
            <h2 className="general-content white-font raleway">
              <span className="bold">Email: </span>
              <a href="mailto:erwan.mocquet52@hotmail.fr">
                erwan.mocquet52@hotnail.fr
              </a>
            </h2>
          </div>
        </section>
      </section>
      <section>
        <Footer />
      </section>
    </main>
  );
}
